import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class HttpErrorinterceptor implements HttpInterceptor {
    constructor(private router: Router) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if (err.status === 401) {

                localStorage.removeItem('OlympusToken');
                localStorage.removeItem('Olympus_FirstName');
                localStorage.removeItem('Olympus_LastName');
                localStorage.removeItem('Olympus_LodgeId');
                localStorage.removeItem('Olympus_SubLodgeId');
                localStorage.removeItem('Olympus_UserId');
                localStorage.removeItem('Olympus_Email');
                localStorage.removeItem('Olympus_UserImage');
                localStorage.removeItem('Olympus_Role_id');
                localStorage.removeItem('Olympus_EmailVerifyed');
                localStorage.removeItem('Olympus_Degrees');
                // auto logout if 401 response returned from api
                this.router.navigate(['/account/login']);
            }

            const error = err.error.message || err.statusText;
            return throwError(error);
        }));
    }
}

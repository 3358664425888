import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams  } from '@angular/common/http';
import {ProfileService} from '../../service/profile.service';
import {ToasterMsgService} from '../../service/toaster.service';
import {AccountService} from '../../service/account.service';
import { Router } from '@angular/router';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.css']
})
export class ContactusComponent implements OnInit {

  hideheader = false;
  name = '';
  email = '';
  subject = '';
  message = '';

  userId = window.localStorage.getItem('Olympus_UserId');

  constructor(
    private titleService: Title,
    private accountService: AccountService,
    private ToasterService: ToasterMsgService,
    private router: Router,
    private profileService: ProfileService) {
    this.titleService.setTitle('Contact Us | Olympus');
  }

  ngOnInit(): void {
    if (this.userId == null || this.userId === ''){
      this.hideheader = true;

    }
    else{
      this.hideheader = false;
    }
  }

  cancelClick(){
    this.name = '';
    this.email = '';
    this.subject = '';
    this.message = '';
  }

  SubmitClick(){
    const body = new HttpParams()
    .set('Name', this.name)
    .set('Email', this.email)
    .set('Subject', this.subject)
    .set('Message', this.message);

    this.accountService.PostSubmitContactData(body).subscribe(
      res => {
        this.ToasterService.showToast('success', 'Thank you!', 'Your message has been successfully sent. We will contact you very soon!');
        this.name = '';
        this.email = '';
        this.subject = '';
        this.message = '';
      });
  }

}

<header class="header" *ngIf="!hideheader">
	<div class="container">
		<div class="row align-items-center">
			<div class="col-auto">
				<div class="logo">
					<a [routerLink]="['../home']">
						<img [src]="logo" alt="">
					</a>
				</div>
			</div>
			<div class="col-auto col-md navCol">
				<div class="mainNav">
					<button class="mobileNavBtn">
						<span></span>
						<span></span>
						<span></span>
					</button>
					<div class="overLay"></div>
					<nav class="navigation" style="float: right;">
						<ul>
							
							<li><a (click)="hidemenu()" [routerLink]="['../account/login']">SIGN IN</a></li>
							<li><a (click)="hidemenu()" [routerLink]="['../account/signup']">SIGN UP</a></li>
							
						</ul>
					</nav>
				</div>
			</div>
			<div class="col col-md-auto">
				
			</div>
		</div>
	</div>
</header>

<!-- 

<header class="header" *ngIf="!hideheader">
	<div class="container">
		<div class="row align-items-center">
			<div class="col-auto">
				<div class="logo">
					<a [routerLink]="['../home']">
						<img [src]="logo" alt="">
					</a>
				</div>
			</div>
			<div class="col-auto col-md navCol">
				<div class="mainNav">
					<button class="mobileNavBtn">
						<span></span>
						<span></span>
						<span></span>
					</button>
					<div class="overLay"></div>
					<nav class="navigation" style="float: right;">
						<ul>
							<li><a (click)="hidemenu()" [routerLink]="['../account/login']">SIGN IN</a></li>
							<li><a (click)="hidemenu()" [routerLink]="['../account/signup']">SIGN UP</a></li>
							
						</ul>
					</nav>
				</div>
			</div>
			<div class="col col-md-auto">

			</div>
		</div>
	</div>
</header> -->


<header class="header" *ngIf="!hideheader">
	<div class="container">
		<div class="row align-items-center">
			
			<div class="col-auto">
				
				<div class="logo">
					<a [routerLink]="['../home']">
						<img [src]="logo" alt="">
					</a>
				</div>
			</div>
			<div class="col-auto col-md navCol">
				<div class="mainNav">
					<button class="mobileNavBtn">
						<span></span>
						<span></span>
						<span></span>
					</button>
					<div class="overLay"></div>
					<nav class="navigation">
						<ul>
							<li><a (click)="hidemenu()" [routerLink]="['../home']">Home</a></li>
							<li><a (click)="hidemenu()" [routerLink]="['../events/upcoming']">Events</a></li>
							<li><a (click)="hidemenu()" [routerLink]="['../resources/businesses']">Businesses</a></li>
							<li><a (click)="hidemenu()" [routerLink]="['../gallery']">Gallery</a></li>
							<li><a (click)="hidemenu()" [routerLink]="['../resources/lecture']">Blog</a></li>
							<li><a (click)="hidemenu()" class="blueBtn" [routerLink]="['../resources/philanthropy']">Donate</a></li>
						</ul>
					</nav>
				</div>
			</div>
			<div class="col col-md-auto">
				<div class="topRightLink">
					<div class="searchEle">
						<span class="searchIcon"></span>
						<div class="searchBox">
							<input type="text" #input placeholder="Search here..." id="searchtop" (keyup)="SearchClick($event)" ng-keypress="checkIfEnterKeyWasPressed($event)" [(ngModel)]="searchtext" (selectItem)="selectedItem($event,input)"  name="searchtop" [ngbTypeahead]="search" [inputFormatter]="inputFormat" [resultFormatter]="resultFormat" #instance="ngbTypeahead">
						</div>
					</div>
					<div class="loginLink" *ngIf="chkimg"><span class="circle" style="background: none;"><img [src]="imgurl" class="rounded-circle" ></span></div>
					<div class="loginLink" *ngIf="!chkimg"><span class="circle">{{firstchar}}</span></div>
					<div class="linkDropDown">
						<ul>
							<li><a href="javascript:void(0);" (click)="detailProfile()">My profile</a></li>
							<li><a [routerLink]="['../resources/paydues']">Pay Dues</a></li>
							<li><a [routerLink]="['../profiles/changepassword']">Change password</a></li>
							<!-- <li><a [routerLink]="['../communication/enter-room']">Room</a></li> -->
							<li><a [routerLink]="['../profiles/account']">Payment Method</a></li>
							<li><a [routerLink]="['../resources/cashregister/receipt']">Receipts</a></li>
							<li><a href="javascript:void(0);" (click)="UserLogout()">Logout</a></li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
</header>


<!-- <header class="header" *ngIf="!hideheader">
	<div class="container">
		<div class="row align-items-center">
			<div class="col-auto">
				<div class="logo">
					<a [routerLink]="['../home']">
						<img [src]="logo" alt="">
					</a>
				</div>
			</div>
			<div class="col-auto col-md navCol">
				<div class="mainNav">
					<button class="mobileNavBtn">
						<span></span>
						<span></span>
						<span></span>
					</button>
					<div class="overLay"></div>
					<nav class="navigation">
						<ul>
							<li><a (click)="hidemenu()" [routerLink]="['../home']">Home</a></li>
							<li><a (click)="hidemenu()" [routerLink]="['../events/upcoming']">Events</a></li>
							<li class="hasChild">
								<a href="javascript:void(0)">Resources</a>
								<div class="subMenu">
									<ul>
										<li><a (click)="hidemenu()" [routerLink]="['../resources/paydues']">Dues</a></li>
										<li><a (click)="hidemenu()" [routerLink]="['../resources/lecture']">Valley Lectures</a></li>

                                   </ul>
								</div>
							</li>
							<li><a (click)="hidemenu()" [routerLink]="['../gallery']">Gallery</a></li>
							<li><a (click)="hidemenu()" class="blueBtn" [routerLink]="['../resources/philanthropy']">Donate</a></li>
						</ul>
					</nav>
				</div>
			</div>
			<div class="col col-md-auto">
				<div class="topRightLink">
					<div class="searchEle">
						<span class="searchIcon"></span>
						<div class="searchBox">
							<input type="text" #input placeholder="Search here..." id="searchtop" (keyup)="SearchClick($event)" ng-keypress="checkIfEnterKeyWasPressed($event)" [(ngModel)]="searchtext" (selectItem)="selectedItem($event,input)"  name="searchtop" [ngbTypeahead]="search" [inputFormatter]="inputFormat" [resultFormatter]="resultFormat" #instance="ngbTypeahead">
						</div>
					</div>
					<div class="loginLink" *ngIf="chkimg"><span class="circle" style="background: none;"><img [src]="imgurl" class="rounded-circle" ></span><span class="text">{{name}}</span></div>
					<div class="loginLink" *ngIf="!chkimg"><span class="circle">{{firstchar}}</span><span class="text">{{name}}</span></div>
					<div class="linkDropDown">
						<ul>
							<li><a href="javascript:void(0);" (click)="detailProfile()">My profile</a></li>
							<li><a [routerLink]="['../profiles/changepassword']">Change password</a></li>
							<li><a [routerLink]="['../profiles/account']">Payment Method</a></li>
							<li><a [routerLink]="['../resources/cashregister/receipt']">Receipts</a></li>
							<li><a href="javascript:void(0);" (click)="UserLogout()">Logout</a></li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
</header> -->

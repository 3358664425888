
<section >
    <div class="container" style="height: 460px;">
        <!-- <h3 class="row heading padding-10">
            <span class="float_left">Dear </span>
        </h3> -->
        <h5>Thank you for your response.</h5>
        <h5>Olympus Scottish Rite, NMJ.</h5>
    </div>

</section>


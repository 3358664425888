import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders,HttpParams  } from '@angular/common/http';
import {Observable} from "rxjs/index";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  _getHeaders() {
    let httpHeaders = new HttpHeaders({
      'Content-Type' : 'application/x-www-form-urlencoded',
      "Accept": "application/json"
    });
    let options = {
      headers: httpHeaders
    };

    return options
  }
  constructor(private http: HttpClient) { }
  baseUrl: string = environment.ServerUrl;
  private extractData(res: Response) {
    let body = res.json();
    return body || { };
  }

  HomeSearch(obj: any): Observable<any> {
    const body = new HttpParams()
    .set('Prefix', obj);
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/User/PostHomeSearch", body,headers)
  }


  UserSearch(obj: any): Observable<any> {
    const body = new HttpParams()
    .set('Prefix', obj);
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/User/PostUserSearch", body,headers)
  }

  UserNameSearch(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/User/PostNameSearch", obj,headers);
  }

  GetEmailVerificationSend(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/User/PostEmailVerificationSend", obj,headers);
  }

  GetUserDetail(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/User/GetUserDetail", obj,headers);
  }
  GetlistUser() : Observable<any> {
    return this.http.get(this.baseUrl+"api/User/GetlistUser",{responseType: 'json'});
  }
  GetAllUser() : Observable<any> {
    return this.http.get(this.baseUrl+"api/User/GetAllUser",{responseType: 'json'});
  }
  PostUserLodgeUpdate(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/User/PostUserLodgeUpdate", obj,headers);
  }

  PutFirebaseTokenUpdate(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.put(this.baseUrl+"api/User/PutFirebaseTokenUpdate", obj,headers);
  }

  PutFirebaseAuthUpdate(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.put(this.baseUrl+"api/User/PutFirebaseAuthUpdate", obj,headers);
  }


  GetNameDetail(obj:any) : Observable<any> {
    return this.http.get(this.baseUrl+"api/User/GetNameDetail?email="+obj);
  }

  Login(obj: any): Observable<any> {
    let headers = this._getHeaders();
    const body = new HttpParams()
    .set('Email', obj.Email)
    .set('Password', obj.Password);
    return this.http.post(this.baseUrl+"api/Account/PostLogin", body,headers);
  }

  

  LoginResponse(obj: any): Observable<any> {
    let headers = this._getHeaders();
    
    const body = new HttpParams()
    .set('TimeZone', obj.TimeZone)
    .set('accesstoken', obj.accesstoken)
    .set('expire', obj.expire)
    .set('id', obj.id);
    return this.http.post(this.baseUrl+"api/Account/PostLoginRes", body,headers);
  }

  PostUserChangePassword(obj: any) : Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/User/PostUserChangePassword",obj,headers);
  }

  CheckCurrentPassword(obj: any) : Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/User/CheckCurrentPassword",obj,headers);
  }

  CheckPassword(obj: any) : Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/User/CheckPassword",obj,headers);
  }

  
  getValidation(obj: any) : Observable<any> {
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/Account/getValidation/"+obj,headers);
  }
  getToken(obj: any) : Observable<any> {
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/Account/getToken/"+obj,headers);
  }

  DeleteUser(obj: any): Observable<any> {
    let httpHeaders = new HttpHeaders({
      'Content-Type' : 'application/x-www-form-urlencoded',
      "Accept": "application/json"
    });
    let options = {
      headers: httpHeaders,
      body: obj
    };
    return this.http.delete(this.baseUrl+"api/User/DeleteUser", options);
  }
}

<footer class="footer">
	<div class="container">
		<div class="row">
			<div class="col-md-6 order-md-1 animation ani">
				<div class="footerForm">
					<form class="form" name="contactus" id="contactus" #contactus="ngForm">
					<h2>Contact us</h2>
					
					<div class="form-group">
						<label class="form-lable">Name</label>
						<input type="text" class="form-control" [(ngModel)]="contactname" id="contactname" name="contactname" required>
					</div>
					<div class="form-group">
						<label class="form-lable">Email</label>
						<input type="text" class="form-control" [(ngModel)]="email" id="email" name="email" required>
					</div>
					<div class="form-group">
						<label class="form-lable">How can we help you?</label>
						<textarea rows="3" class="form-control" [(ngModel)]="disc" id="disc" name="disc" required></textarea>
					</div>
					<div class="btnRow">
						<button class="blueBtn" type="button" (click)="SubmitClick()" [disabled]="contactus.form.invalid">Submit</button>
					</div>
				</form>
				</div>
			</div>
			<div class="col-md-6 footerLeft animation">
				<div class="footerLogo">
					<a [routerLink]="['../home']">
						<img [src]="logo" alt="">
					</a>
				</div>
				<h2>Contact</h2>
				<div class="footerText">
					<!-- <p>We normally respond within 48 hours...</p> -->
					<p [innerHTML]="transform(valley_description)">
					</p>
				</div>
				<div class="footerEmail"><a href="#">{{valley_emails}}</a></div>
				<h3>Follow Us</h3>
				<ul class="socilLink">
					<li *ngIf="sociallinkhide(Twitter)"><a class="twitter" href="{{sociallinkcheck(Twitter)}}" target="_blank"></a></li>
					<li *ngIf="sociallinkhide(Facebook)"><a class="facebook"  href="{{sociallinkcheck(Facebook)}}" target="_blank"></a></li>
					<li *ngIf="sociallinkhide(LinkedIn)"><a class="linked" href="{{sociallinkcheck(LinkedIn)}}" target="_blank"></a></li>
					<li *ngIf="sociallinkhide(Youtube)"><a class="youtube" href="{{sociallinkcheck(Youtube)}}" target="_blank"></a></li>
					<li *ngIf="sociallinkhide(Pinterest)"><a class="print" href="{{sociallinkcheck(Pinterest)}}" target="_blank"></a></li>
				</ul>
				<div class="copyRight">Copyright © 2021 {{valley_name}}. All rights reserved.</div>
			</div>
		</div>
	</div>
</footer>

<!--
<footer class="footer">
	<div class="container">
		<div class="row no-gutters align-items-center">
			<div class="col colA">
        <div class="logo">
          <a [routerLink]="['../home']">
            <img [src]="logo" alt="">
          </a>
        </div>
			</div>
			<div class="col colB">
				<div class="searchBlock" *ngIf="login">
					<div class="inputRow">
						<input type="text" #input placeholder="Search here..." id="searchtextfooter" name="searchtextfooter" [(ngModel)]="searchtext" (keyup)="SearchClick($event)" (selectItem)="selectedItem($event,input)"  name="searchtop" [ngbTypeahead]="search" [inputFormatter]="inputFormat" [resultFormatter]="resultFormat" #instance="ngbTypeahead">
						<button class="searchBtn" type="submit" (click)="UserSearch()"></button>
					</div>
				</div>
				<div class="footerLink">
						<a [routerLink]="['../home']">Home</a>
                        <a [routerLink]="['../about']">About Us</a>
                        <a [routerLink]="['../privacypolicy']">Privacy Policy</a>
                        <a [routerLink]="['../termsofuse']">Terms of Use</a>
                        <a [routerLink]="['../contactus']">Contact Us</a>
				</div>
				<div class="copyright">Copyright &copy; 2021 Olympus. All rights reserved.&nbsp; <span class="textA">Powered By <a target="_blank" href="https://blueportal.io/">Olympus</a></span> </div>
			</div>
		</div>
	</div>
</footer> -->

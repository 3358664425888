import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, Route } from '@angular/router';
import { Observable } from 'rxjs';
import {ProfileService} from '../service/profile.service';

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate {

  userid = '';
  token = '';
  constructor(
    // tslint:disable-next-line:variable-name
    private _router: Router,
    private profileService: ProfileService) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (window.localStorage.getItem('OlympusToken')) {
      this.userid = window.localStorage.getItem('Olympus_UserId');
      this.token = window.localStorage.getItem('OlympusToken');
      return true;
    }
    // navigate to login page
    this._router.navigate(['/account/login']);
    return false;
  }

}

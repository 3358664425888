import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-communication',
  template: `
  <router-outlet></router-outlet>
  `
})
export class CommunicationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

<div class="">
   <div class="middle">
        <div class="container">
            <div class="loginBox">
                <h1>Contact Us</h1>
                <form class="form" name="contactform" id="contactform" #contactform="ngForm">
                    <div class="form-group">
                        <label class="title">Name</label>
                        <input type="text" class="form-control" [(ngModel)]="name" id="name" name="name" [ngModelOptions]="{standalone: true}" placeholder="Name" required>
                    </div>
                    <div class="form-group">
                        <label class="title">Email</label>
                        <input type="text" class="form-control" [(ngModel)]="email" id="email" name="email" placeholder="Email" required>
                    </div>
                    <div class="form-group">
                        <label class="title">Subject</label>
                        <input type="text" class="form-control" [(ngModel)]="subject" id="subject" placeholder="Subject" name="subject" required>
                    </div>
                    <div class="form-group">
                        <label class="title">Message </label>
                        <textarea class="form-control" placeholder="Message" rows="4" name="message" [(ngModel)]="message"></textarea>
                    </div>
                    <br>
                    <button class="yellowBtn" type="button" (click)="SubmitClick()" [disabled]="contactform.form.invalid">Submit</button>
                </form>
            </div>
        </div>
    </div>
</div>

<!-- <div class="main_wrapper index">

    <section class="application_form">
        <div class="container">
            <h3 class="row heading padding-10">
                <span class="float_left">  Contact Us</span>
            </h3>
            <form role="form" name="contactform" id="contactform" class="form-validation" #contactform="ngForm">
                <div class="form-group">
                    <div class="row">
                        <div class="col-md-12 mt">
                            <div class="row">
                                <div class="col-md-2 col-sm-5 col-xs-12 ">
                                    <label for="text" class="control-label label_r">Name :</label>
                                </div>
                                <div class="col-md-4  col-xs-12">
                                    
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-md-12 mt">
                            <div class="row">
                                <div class="col-md-2 col-sm-5 col-xs-12">
                                    <label for="text" class="control-label label_r">Email :</label>
                                </div>
                                <div class="col-md-4  col-xs-12">
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 mt">
                            <div class="row">
                                <div class="col-md-2 col-sm-5 col-xs-12">
                                    <label for="text" class="control-label label_r">Subject :</label>
                                </div>
                                <div class="col-md-6  col-xs-12">
                                    
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-md-12 mt">
                            <div class="row">
                                <div class="col-md-2 col-sm-5 col-xs-12">
                                    <label for="text" class="control-label label_r">Message :</label>
                                </div>
                                <div class="col-md-6  col-xs-12">
                                    
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="row">
                        <div class="col-md-2  mt">
                        </div>
                        <div class="col-md-4  mt">
                            <button type="submit" class="btn blue_btton_upload" (click)="SubmitClick()" [disabled]="contactform.form.invalid"><i class="fa fa-check-square-o" aria-hidden="true"></i>&nbsp;Submit</button>
                            <button type="reset" class="btn blue_btton_upload" (click)="cancelClick()"><i class="fa fa-repeat" aria-hidden="true"></i>&nbsp;Reset</button>


                        </div>

                    </div>
                </div>
            </form>
        </div>
    </section>
</div> -->





import { NgModule,APP_INITIALIZER  } from '@angular/core';
import { CommonModule } from '@angular/common';
import {AdminPaymentService} from './admin-payment.service';
import {ApplicationService} from './application.service';
import {ApproverService} from './approver.service';
import {DesignationService} from './designation.service';
import {DonationOptionService} from './donation-option.service';
import {EagleJournalService} from './eagle-journal.service';
import {EditProfileService} from './edit-profile.service';
import {EventService} from './event.service';
import {GrandLodgeService} from './grand-lodge.service';
import {JournalService} from './journal.service';
import {LectureService} from './lecture.service';
import {LodgeService} from './lodge.service';
import {OfficersService} from './officers.service';
import {ProfileService} from './profile.service';
import {SettingService} from './setting.service';
import {UserSettingService} from './user-setting.service';
import {WhatsnewService} from './whatsnew.service';
import {YoutubeService} from './youtube.service';
import {FileuploadService} from './fileupload.service';
import {PaynowService} from './paynow.service';
import {MemberService} from './member.service';
import {AccountService} from './account.service';
import {PaymentService} from './payment.service';
import {SearchService} from './search.service';
import { AuthGuard } from '../guards/auth-guard.service';
import { ConfirmationDialogService } from './confirmation-dialog.service';
import { LoaderService } from './loader.service';
import { DegreeService } from './degree.service';
import { NotificationService } from './notification.service';
import { PushnotificationService } from './pushnotification.service';
import { MessagingService } from './messaging.service';
import { HomeService } from './home.service';
import { CaptureAudioService } from './capture-audio.service';
import { CaptureScreenService } from './capture-screen.service';
import { BusinesscategoryService } from './businesscategory.service';
import { BusinessService } from './business.service';
import { AsyncPipe } from '../../../node_modules/@angular/common';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers:[
    AsyncPipe,
    AuthGuard,
    AdminPaymentService,
    ApplicationService,
    ApproverService,
    DesignationService,
    DonationOptionService,
    EagleJournalService,
    EditProfileService,
    EventService,
    GrandLodgeService,
    JournalService,
    LectureService,
    LodgeService,
    OfficersService,
    ProfileService,
    SettingService,
    UserSettingService,
    WhatsnewService,
    YoutubeService,
    MemberService,
    ConfirmationDialogService,
    FileuploadService,
    PaynowService,
    AccountService,
    PaymentService,
    SearchService,
    LoaderService,
    DegreeService,
    NotificationService,
    MessagingService,
    PushnotificationService,
    HomeService,
    CaptureAudioService,
    CaptureScreenService,
    BusinesscategoryService,
    BusinessService
  ]
})

export class ServiceModule {
}

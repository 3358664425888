import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams  } from '@angular/common/http';
import { ProfileService } from '../service/profile.service';
import {SettingService} from '../service/setting.service';
import { LodgeService } from '../service/lodge.service';
import {AccountService} from '../service/account.service';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, filter, switchMap, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { UserSettingService } from '../service/user-setting.service';
import { DomSanitizer } from '@angular/platform-browser';
import {ToasterMsgService} from '../service/toaster.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  
  login = false;
  searchtext = null;
  searchDropdown = 'Event';
  logo = '';

  Facebook = '';
  LinkedIn = '';
  Instagram = '';
  Twitter = '';
  Pinterest = '';
  Youtube = '';

  contactname = '';
  email = '';
  disc = '';

  valley_name = "";
  valley_address = "";
  valley_phone = "";
  valley_supremecouncil = "";
  valley_jurisdiction = "";
  valley_description = "";
  valley_email_rec = "";
  valley_emails = "";
  valley_photo="";


  googleStoragePath: string = environment.GoogleStoragePath;
  constructor(
    private LodgeService: LodgeService,
    private SettingService:SettingService,
    private accountService: AccountService,
    private profileService: ProfileService,
    private userSettingService: UserSettingService,
    private ToasterService: ToasterMsgService,
    private sanitizer: DomSanitizer,
    private router: Router) {

  }

  transform(value: any): any {
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }

  selectedItem(obj, input){
    obj.preventDefault();
    // tslint:disable-next-line:variable-name
    let _type = '';
    let text = '';
    input.value = '';
    if (obj.item != null){
      if (obj.item.type === 'user'){
        _type = 'Brother';
        text = obj.item.name;
        this.searchtext = null;
        this.ProfileView(obj.item.value);
      }
      else if (obj.item.type === 'event'){
        _type = 'Event';
        text = obj.item.name;
        this.searchtext = null;
        this.EventView(obj.item.value);
      }
    }
  }

  ProfileView(UserId){
    localStorage.setItem('Olympus_ProfileSeach', '1');
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/profiles/detail/' + UserId]);
    });
  }

  EventView(EventId){
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/events/detail/' + EventId]);
    });
  }

  isObject(val) {
    return (typeof val === 'object');
  }

  resultFormat(value: any) {
    if (value.type === 'user'){
      return 'Brother : '  + value.name;
    }
    else if (value.type === 'event'){
      return 'Event : '  + value.name;
    }
    else{
      return value.name;
    }

  }

  inputFormat(value: any)   {
    if (value.name) {
      return value.name;
    }
    return value;
  }

  search = (text$: Observable<string>) => {
    return text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap( (searchText) =>  this.profileService.HomeSearch(searchText))
    );
  }

  SearchClick(e){
    if (e.keyCode === 13){

    }
  }

  UserSearch(){
    if (this.searchtext != null){
      if (this.searchtext !== ''){
        let text = null;
        if (this.isObject(this.searchtext)){
          text = this.searchtext.name;
        }
        else{
            text = this.searchtext;
        }
        localStorage.setItem('Olympus_SearchText', text);
        localStorage.setItem('Olympus_SearchType', this.searchDropdown);
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
          this.router.navigate(['/search']);
        });
        window.scrollTo(0, 0);
      }
    }
  }

  getSocialLinks(){
    this.SettingService.GetSocialMediaSetting().subscribe(
      datares => {
        if(datares != null){
          this.Facebook = datares.facebook;
          this.LinkedIn = datares.linkedin;
          this.Instagram = datares.instagram;
          this.Twitter = datares.twitter;
          this.Pinterest = datares.pinterest;
          this.Youtube = datares.youtube;
        }
      });
  }
  getDetail(){
    this.userSettingService.GetDetail().subscribe(
      datares => {
        //this.logo = this.googleStoragePath + 'Upload/Logo/' + datares.LogoName;
        this.logo = datares.LogoName;
      });
  }

  getLodge(){
    this.LodgeService.GetSingleLodge().subscribe(
      datares => {
        this.valley_name = datares.name;
        this.valley_emails = datares.email;
        
        // var lastChar = _valley_emails.substr(_valley_emails.length - 1);
        // if(lastChar == ","){
        //   this.valley_emails = datares.ApplicationReceivers.slice(0,-1); 
        // }
        // else{
        //   this.valley_emails = datares.ApplicationReceivers; 
        // }
        this.valley_description=datares.text;
    });
  }

  SubmitClick(){
    var name = this.contactname;
    const body = new HttpParams()
    .set('Name', name)
    .set('Email', this.email)
    .set('Subject', "Contact us")
    .set('Message', this.disc);

    this.accountService.PostSubmitContactData(body).subscribe(
      res => {
        this.ToasterService.showToast('success', 'Thank you!', 'Your message has been successfully sent. We will contact you very soon!');
        this.contactname = '';
        this.email = '';
        this.disc = '';
      });
  }

  sociallinkcheck(link){
    if(link == null || link == "null"){
      return "javascript:void(0);";
    }
    return link;
  }

  sociallinkhide(link){
    if(link == null || link == "null" || link == ""){
      return false;
    }
    return true;
  }

  ngOnInit(): void {
    this.getDetail();
    this.getSocialLinks();
    this.getLodge();
    if (localStorage.getItem('OlympusToken')) {
      this.login = true;
    }
    else{
      this.login = false;
    }
  }

}

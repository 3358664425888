import { Component, OnInit } from '@angular/core';
import { map } from "rxjs/operators";
import {EventService} from '../../service/event.service';
import {ToasterMsgService} from '../../service/toaster.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-scan',
  templateUrl: './scan.component.html',
  styleUrls: ['./scan.component.css']
})
export class ScanComponent implements OnInit {

  roleId = window.localStorage.getItem('Olympus_Role_id');
  userId = window.localStorage.getItem('Olympus_UserId');

  availableDevices: MediaDeviceInfo[];
  currentDevice: MediaDeviceInfo = null;
  hasDevices: boolean;
  hasPermission: boolean;
  qrResult: any;
  guestExist: boolean;


  constructor(private router: Router,private ToasterService: ToasterMsgService,private eventService: EventService) { 

  }

  //Clears the QR code scanned
  clearResult(): void {
    this.qrResult = null;
  }

  //Scans the QR code
  onCodeResult(resultString: string): void {
    this.guestExist = null;
    if (this.checkQRJSON(resultString)) {
      this.qrResult = JSON.parse(resultString);
      this.checkInGuest(this.qrResult);
      this.clearMessage();
    } else {
      this.guestExist = false;
      this.clearMessage();
    }
  }

  //Permission for the app to use the device camera
  onHasPermission(has: boolean): void {
    this.hasPermission = has;
  }

  //Checks if the QR code belongs to a valid guest
  checkInGuest(guestQR: any): void {
    var _eventid = guestQR;
    this.eventService.GetEventCheckIn(_eventid,this.userId).subscribe(x => {
      if(x == "Success"){
        this.ToasterService.showToast('success', 'Event', 'you are check-in successfully');
        this.router.navigate(['/events/detail/'+_eventid]);
      }
      else if(x == "User Not Found"){
        this.ToasterService.showToast('error', 'Event', 'user not found');
      }
      else{
        this.ToasterService.showToast('error', 'Event', 'Please try again,something wrong..');
      }
    });
  }

  clearMessage() {
    setTimeout(() => {
      this.guestExist = null;
    }, 3000);
  }

  //This function check if the QR code has a valid JSON as data
  checkQRJSON(qrString: string): boolean {
    if (
      /^[\],:{}\s]*$/.test(
        qrString
          .replace(/\\["\\\/bfnrtu]/g, "@")
          .replace(
            /"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g,
            "]"
          )
          .replace(/(?:^|:|,)(?:\s*\[)+/g, "")
      )
    ) {
      return true;
    } else {
      return false;
    }
  }

  ngOnInit(): void {
  }

}

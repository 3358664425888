import { Component, OnInit } from '@angular/core';
import {UserSettingService} from '../../service/user-setting.service';
import { DomSanitizer } from '@angular/platform-browser';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-termsofuse',
  templateUrl: './termsofuse.component.html',
  styleUrls: ['./termsofuse.component.css']
})
export class TermsofuseComponent implements OnInit {
  previewHtml = null;
  constructor(private titleService:Title,private UserSettingService:UserSettingService,private sanitizer:DomSanitizer) { 
    this.titleService.setTitle("Terms of use | Olympus");
    this.UserSettingService.GetPolicy(1).subscribe(
      res => {
        this.previewHtml = res.TermsOfUse;
      });

  }

  transform(value: any): any {
    return this.sanitizer.bypassSecurityTrustHtml(value);
    // return this.sanitizer.bypassSecurityTrustStyle(style);
    // return this.sanitizer.bypassSecurityTrustXxx(style); - see docs
  }

  ngOnInit(): void {
  }

}

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule,FormsModule } from '@angular/forms';
import { HttpClientModule,HTTP_INTERCEPTORS } from '@angular/common/http';
import {ServiceModule} from "./service/service.model";
import { HttpRequestInterceptor } from './interceptors/interceptor';
import { HttpErrorinterceptor } from './interceptors/http-errorinterceptor';
import { LoaderInterceptor } from './interceptors/loader';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MaterialElevationDirective } from './directive/material-elevation.directive';
import { OnlynumberDirective } from './directive/onlynumber.directive';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { ToastrModule } from 'ngx-toastr';

import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './pages/home/home.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderWithloginComponent } from './header-withlogin/header-withlogin.component';
import { EventsComponent } from './pages/events/events.component';
import { OfficersComponent } from './pages/officers/officers.component';
import { ResourcesComponent } from './pages/resources/resources.component';
import { GalleryComponent } from './pages/gallery/gallery.component';
import { ProfilesComponent } from './pages/profiles/profiles.component';
import { AboutComponent } from './pages/about/about.component';
import { PrivacypolicyComponent } from './pages/privacypolicy/privacypolicy.component';
import { TermsofuseComponent } from './pages/termsofuse/termsofuse.component';
import { ContactusComponent } from './pages/contactus/contactus.component';
import { AccountComponent } from './pages/account/account.component';
import { EmailresComponent } from './pages/emailres/emailres.component';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { environment } from '../environments/environment';
import { NgxQRCodeModule } from "ngx-qrcode2";
import { ZXingScannerModule } from "@zxing/ngx-scanner";

import {
  NgbModule,
  NgbPaginationModule,
  NgbTypeaheadModule
  } from '@ng-bootstrap/ng-bootstrap';
import { EmailverifyComponent } from './pages/emailverify/emailverify.component';
import { MyLoaderComponent } from './pages/my-loader/my-loader.component';
import { CommunicationComponent } from './pages/communication/communication.component';
import { StripepaymentComponent } from './pages/stripepayment/stripepayment.component';
import { TextMaskModule } from 'angular2-text-mask';
import { ScanComponent } from './pages/scan/scan.component';

@NgModule({
  declarations: [
    OnlynumberDirective,
    ConfirmationDialogComponent,
    MaterialElevationDirective,
    AppComponent,
    HeaderComponent,
    HomeComponent,
    FooterComponent,
    HeaderWithloginComponent,
    EventsComponent,
    OfficersComponent,
    ResourcesComponent,
    GalleryComponent,
    ProfilesComponent,
    AboutComponent,
    PrivacypolicyComponent,
    TermsofuseComponent,
    ContactusComponent,
    AccountComponent,
    EmailresComponent,
    EmailverifyComponent,
    MyLoaderComponent,
    CommunicationComponent,
    StripepaymentComponent,
    ScanComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    NgbModule,
    NgbPaginationModule,
    NgbTypeaheadModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    TextMaskModule,
    AngularFireModule.initializeApp(environment.firebase),
    ZXingScannerModule,
    NgxQRCodeModule
  ],
  entryComponents: [ConfirmationDialogComponent,StripepaymentComponent],
  providers: [ServiceModule,
    { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorinterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders  } from '@angular/common/http';
import {Observable} from "rxjs/index";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class UserSettingService {

  _getHeaders() {
    let httpHeaders = new HttpHeaders({
      'Content-Type' : 'application/x-www-form-urlencoded',
      "Accept": "application/json"
    });
    let options = {
      headers: httpHeaders
    };

    return options
  }
  constructor(private http: HttpClient) { }
  baseUrl: string = environment.ServerUrl;

  GetDetail() : Observable<any> {
    return this.http.get(this.baseUrl+"api/UserSet/GetDetail",{responseType: 'json'});
  }

  

  GetAllSlider() : Observable<any> {
    return this.http.get(this.baseUrl+"api/UserSet/GetAllSlider",{responseType: 'json'});
  }

  GetAllPublicSlider() : Observable<any> {
    return this.http.get(this.baseUrl+"api/UserSet/GetAllPublicSlider",{responseType: 'json'});
  }

  GetAllPrivateSlider() : Observable<any> {
    return this.http.get(this.baseUrl+"api/UserSet/GetAllPrivateSlider",{responseType: 'json'});
  }

  GetLink() : Observable<any> {
    return this.http.get(this.baseUrl+"api/UserSet/GetLink",{responseType: 'json'});
  }

  GetSliderDetail(obj:any) : Observable<any> {
    return this.http.get(this.baseUrl+"api/UserSet/GetSlider/" + obj,{responseType: 'json'});
  }

  GetPolicy(obj:any) : Observable<any> {
    return this.http.get(this.baseUrl+"api/PolicyTerms/GetPolicy/" + obj,{responseType: 'json'});
  }

  GetSliderLink(obj:any) : Observable<any> {
    return this.http.get(this.baseUrl+"api/UserSet/GetSliderLink/" + obj,{responseType: 'json'});
  }

  PostDetailSlider(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.put(this.baseUrl+"api/UserSet/PUTSlider", obj,headers);
  }

  DeleteSlider(obj: any): Observable<any> {
    return this.http.delete(this.baseUrl+"api/UserSet/DeleteSlider/"+obj);
  }

  
  GetSingalLink(obj:any) : Observable<any> {
    return this.http.get(this.baseUrl+"api/UserSet/GetSingalLink/" + obj,{responseType: 'json'});
  }

  PostLink(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/UserSet/PostLink", obj,headers);
  }

  PutLink(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.put(this.baseUrl+"api/UserSet/PutLink", obj,headers);
  }

  DeleteLink(obj: any): Observable<any> {
    return this.http.delete(this.baseUrl+"api/UserSet/DeleteLink/"+ obj);
  }

  PostSliderLink(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/UserSet/PostSliderLink", obj,headers);
  }

  DeleteSliderLink(obj: any): Observable<any> {
    return this.http.delete(this.baseUrl+"api/UserSet/DeleteSliderLink/"+ obj);
  }

  PostPolicy(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/PolicyTerms/PostPolicy", obj,headers);
  }

  PostTerms(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/PolicyTerms/PostTerms", obj,headers);
  }
  DeleteLogo(obj: any): Observable<any> {
    return this.http.delete(this.baseUrl+"api/UserSet/DeleteLogo/"+obj);
  }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { environment } from '../../environments/environment';
import { UserSettingService } from '../service/user-setting.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  logo = '';
  googleStoragePath: string = environment.GoogleStoragePath;
  currentpath: string;
  hideheader = false;
  constructor(
    private userSettingService: UserSettingService,
    location: Location,
    router: Router
  ) {
    router.events.subscribe((val) => {
        this.currentpath = location.path();
        this.HeaderSetting(this.currentpath);
    });
  }

  HeaderSetting(url){
    if (url === '/account/login' || url === '/account/signup' || url === '/account/forgotpassword'){
      this.hideheader = true;
    }
    else{
      this.hideheader = false;
    }

  }

  hidemenu(){
    if ($('body').hasClass('openNav')) {
      $('body').removeClass('openNav');
    }
  }

  getDetail(){
    this.userSettingService.GetDetail().subscribe(
      datares => {
        this.logo = this.googleStoragePath + 'Upload/Logo/' + datares.LogoName;
    });
  }

  ngOnInit(): void {
   this.getDetail();
  }

}

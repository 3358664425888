import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { HttpParams  } from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {ToasterMsgService} from '../../service/toaster.service';
import {AccountService} from '../../service/account.service';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-emailverify',
  templateUrl: './emailverify.component.html',
  styleUrls: ['./emailverify.component.css']
})
export class EmailverifyComponent implements OnInit {
  id: any;
  paramsSub: any;
  alertsuccess = false;
  alertdanger = false;

  constructor(
    private titleService: Title,
    private accountService: AccountService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private ToasterService: ToasterMsgService) {
    this.titleService.setTitle('Email Verify | Olympus');
  }

  ngOnInit(): void {
    this.paramsSub = this.activatedRoute.params.subscribe(params => this.id = params.id);
    if (this.id !== null){
      const body = new HttpParams()
      .set('EmailCode', this.id.toString());
      this.accountService.PostEmailVerify(body).subscribe(
        resPost => {
          if (resPost === 'Verify SuccessFull') {
            this.alertdanger = false;
            this.alertsuccess = true;
          }
          else {
            this.alertdanger = true;
            this.alertsuccess = false;
          }
        });
    }

  }
}

import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { HttpParams  } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs'
import { HttpClient,HttpHeaders  } from '@angular/common/http';
import {Observable} from "rxjs/index";
import {environment} from "../../environments/environment";
import { createOfflineCompileUrlResolver } from '@angular/compiler';
import {ProfileService} from './profile.service';

@Injectable({
  providedIn: 'root'
})
export class MessagingService {
  currentMessage = new BehaviorSubject(null);
  userid_id = window.localStorage.getItem('Olympus_UserId');
  // constructor(private angularFireMessaging: AngularFireMessaging) {
  // this.angularFireMessaging.messaging.subscribe(
  // (_messaging) => {
  // _messaging.onMessage = _messaging.onMessage.bind(_messaging);
  // _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
  // }
  // )
  // }

  _getHeadersForm() {
    let httpHeaders = new HttpHeaders({
      'Content-Type' : 'application/x-www-form-urlencoded',
      "Accept": "application/json"
    });
    let options = {
      headers: httpHeaders
    };

    return options
  }

  _getHeaders() {
    let httpHeaders = new HttpHeaders({
      'Content-Type' : 'application/json',
      "Accept": "application/json",
      "Cache-Control": "no-cache",
      'Authorization': 'key=AAAAhAm9_QM:APA91bFc9qfLXX0VYIL5fkZf-HjoqkJrH1Z6nFxLOcjx3n0A8JoIUUaQTLDxewN-GQRerWhvzHU_0SfwrE8WCM87h7P2pz3-KdUkXX1fUjBiLAXXp4a-9bkt0vPxdh5ylgFcyEJU174_',
    });
    let options = {
      headers: httpHeaders
    };

    return options
  }

  constructor(private ProfileService: ProfileService,private http: HttpClient,private angularFireMessaging: AngularFireMessaging) {
    this.angularFireMessaging.messages.subscribe(
    (_messaging: AngularFireMessaging) => {
    _messaging.onMessage = _messaging.onMessage.bind(_messaging);
    _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
    }
    )
  }

  UpdateToken(token){
    const body = new HttpParams()
    .set('id',this.userid_id)
    .set('FirebaseToken',token)
    
    this.ProfileService.PutFirebaseTokenUpdate(body).subscribe(
      datares => {
      
    });
  }

  AddTopicAll(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post("https://iid.googleapis.com/iid/v1/"+obj+"/rel/topics/all", {},headers);
  }

  requestPermission() {
  this.angularFireMessaging.requestToken.subscribe(
  (token) => {
    if(this.userid_id != null){
      this.UpdateToken(token);
      this.AddTopicAll(token).subscribe(
        datares => {
          
      });
    }

  },
  (err) => {
  console.error('Unable to get permission to notify.', err);
  }
  );
  }
  receiveMessage() {
  this.angularFireMessaging.messages.subscribe(
  (payload) => {
  console.log("new message received. ", payload);
  this.currentMessage.next(payload);
  })
  }
}

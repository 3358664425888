<div class="wrapper " [ngClass]="{'homePage': currentpath === '/home'}">
    <div *ngIf="!login">
        <app-header></app-header>
    </div>
    <div *ngIf="login">
        <app-header-withlogin></app-header-withlogin>
    </div>
    <!-- <span>{{ message | async | json }}</span> -->
    <app-my-loader></app-my-loader>
    <router-outlet (activate)="onActivate($event)"></router-outlet>

    <app-footer></app-footer>
</div>
    
    <script src="assets/js/bootstrap.min.js" defer></script> 
    <script src="assets/js/firebase-app.js" defer></script> 
    <script src="assets/js/firebase-messaging.js" defer></script> 
    <script src="assets/js/firebase-firestore.js" defer></script> 
    <script src="assets/js/firebase-analytics.js" defer></script> 
    <script src="assets/js/add-to-homescreen/addtohomescreen.js" defer></script>
    
<script>
    addToHomescreen();
</script>
